import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ZIndexService } from '@garmin-avcloud/avcloud-ui-common/shared';
import { Breakpoints } from '@garmin-avcloud/avcloud-ui-common/style-variables';
import { distinctUntilChanged, Subscription } from 'rxjs';

@Component({
  selector: 'pilot-full-template',
  templateUrl: './full-template.component.html',
  styleUrls: ['./full-template.component.scss']
})
export class FullTemplateComponent implements OnInit, OnDestroy {
  showSidebar = false;
  mobileView = false;
  messageCenterZIndex: number;
  readonly subscriptions: Subscription = new Subscription();

  constructor(private readonly breakpointObserver: BreakpointObserver,
    private readonly zIndexService: ZIndexService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.breakpointObserver.observe([Breakpoints.SmallScreen])
        .pipe(
          distinctUntilChanged()
        )
        .subscribe(({ matches }) => this.mobileView = matches)
    );
    this.messageCenterZIndex = this.zIndexService.nextZIndex();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  toggleSidebarVisibility(showSidebar: boolean): void {
    this.showSidebar = showSidebar;
  }
}
