<div
  *ngIf="open"
  class="account-overlay">
  <a routerLink="/settings">
    User Settings
  </a>
  <a [href]="accountOverlayLink"
    target="_self">
    Manage Account
  </a>
  <button (click)="logOut()">
    Log Out
  </button>
</div>
