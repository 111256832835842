<div
  class="header-container"
  slot="header">
  <div class="sidebar-button-wrapper">
    <button
      class="sidebar-button"
      (click)="toggleSidebar()">
      <div
        class="sidebar-button-line"
        [ngClass]="{'sidebar-button-top-line-animation': showSidebar}">
      </div>
      <div
        class="sidebar-button-line"
        [ngClass]="{'sidebar-button-bottom-line-animation': showSidebar}">
      </div>
    </button>
  </div>
  <a
    class="logo"
    routerLink="/">
    <img
      alt="Garmin Pilot Logo"
      src="assets/garminPilotLogo.svg">
  </a>
</div>
