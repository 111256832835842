import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { AppRouteReuseStrategy } from './app-route-reuse-strategy';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { errorInterceptor } from './core/interceptor/error-interceptor/error-interceptor';
import { AirportDataService } from './features/airport/shared/services/airport-data.service';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule
  ],
  providers: [
    AirportDataService,
    {
      provide: RouteReuseStrategy,
      useClass: AppRouteReuseStrategy
    },
    provideHttpClient(
      withInterceptors([errorInterceptor])
    )
  ]
})
export class AppModule { }
