import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from '@environment';
import { FboServiceApiModule, FboServiceConfiguration } from '@generated/fbo-service';
import { FlightOrchestratorServiceApiModule, FlightOrchestratorServiceConfiguration } from '@generated/flight-orchestrator-service';
import { FlightRouteServiceApiModule, FlightRouteServiceConfiguration } from '@generated/flight-route-service';

const flightOrchestratorServiceApiModuleApiFactory = (): FlightOrchestratorServiceConfiguration => new FlightOrchestratorServiceConfiguration({
  basePath: `${environment.garmin.aviation.servicesHost}/flightOrchestratorService`
});

const flightRouteServiceApiModuleApiFactory = (): FlightRouteServiceConfiguration => new FlightRouteServiceConfiguration({
  basePath: `${environment.garmin.aviation.servicesHost}/flightRouteService`
});

const fboServiceApiModuleApiFactory = (): FboServiceConfiguration => new FboServiceConfiguration({
  basePath: `${environment.garmin.aviation.servicesHost}/fboService`
});

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FboServiceApiModule.forRoot(fboServiceApiModuleApiFactory),
    FlightOrchestratorServiceApiModule.forRoot(flightOrchestratorServiceApiModuleApiFactory),
    FlightRouteServiceApiModule.forRoot(flightRouteServiceApiModuleApiFactory)
  ]
})
export class ApiModule { }
