import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { environment } from '@environment';
import { AuthService } from '@garmin-avcloud/avcloud-web-utils';

enum AccountOverlayLink {
  // The preceding "www" is required for the dev environment
  Dev = 'https://www.dev.garmin.com/account/',
  Prod = 'https://garmin.com/account/'
}

@Component({
  selector: 'pilot-account-overlay',
  templateUrl: './account-overlay.component.html',
  styleUrls: ['./account-overlay.component.scss']
})
export class AccountOverlayComponent implements OnInit {
  @Input() open: boolean = false;
  @Output() readonly clickOutside: EventEmitter<Event> = new EventEmitter();
  accountOverlayLink: string = '';

  constructor(
    private readonly authService: AuthService,
    private readonly elementRef: ElementRef<HTMLDivElement>
  ) {}

  @HostListener('document:click', ['$event'])
  click(event: Event): void {
    if (event.target instanceof Node && !this.elementRef.nativeElement.contains(event.target)) {
      this.clickOutside.emit(event);
    }
  }

  ngOnInit(): void {
    if (environment.name === 'PROD' || environment.name === 'STAGE') {
      this.accountOverlayLink = AccountOverlayLink.Prod;
    } else {
      this.accountOverlayLink = AccountOverlayLink.Dev;
    }
  }

  logOut(): void {
    this.authService.logOut();
  }
}
