import { HttpRequest, HttpEvent, HttpHandlerFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@garmin-avcloud/avcloud-web-utils';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export function errorInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const authService = inject(AuthService);
  const router = inject(Router);
  return next(req).pipe(
    catchError((error) => {
      if (error.status === 401) {
        const parameter = ".com%2F";
        let loginPortal = authService.getSsoPortalUrl();
        loginPortal = loginPortal.substring(0, loginPortal.lastIndexOf(parameter) + parameter.length);
        const route = router.url.slice(1);
        const redirectPath = loginPortal + route;
        authService.logOut(redirectPath);
      }
      return throwError(() => error);
    }
    ));
}