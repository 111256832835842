import { Component, DestroyRef, Input, type OnInit, effect, inject, signal, untracked } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Icons } from '@garmin-avcloud/avcloud-ui-common/icon';
import { AuthService, LocalStorageService, SubscriptionService } from '@garmin-avcloud/avcloud-web-utils';
import * as SharedIcons from '@shared/constants/icons';
import { StatefulUiKey } from '@shared/enums/stateful-ui-key.enum';

@Component({
  selector: 'pilot-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() mobileView = false;

  expandButtonIcon = Icons.ArrowLeft;
  expandedState = true;

  readonly Icons = Icons;
  readonly SharedIcons = SharedIcons;

  private readonly localStorageService = inject(LocalStorageService);
  private readonly authService = inject(AuthService);
  private readonly subscriptionService = inject(SubscriptionService);
  destroyRef: DestroyRef = inject(DestroyRef);

  userIsAuthenticated = signal(false);

  readonly userHasActiveSubscription = signal(false);

  constructor() {
    effect(() => {
      if (this.userIsAuthenticated()) {
        untracked(() => {
          this.subscriptionService
            .hasFeatureTypes('FULLAPP')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((hasFullAppFeature) => this.userHasActiveSubscription.set(hasFullAppFeature));
        });
      }
    });
  }

  ngOnInit(): void {
    this.expandedState = this.localStorageService.get(StatefulUiKey.SidebarExpandedState) ?? true;
    this._updateExpandedButtonState(this.expandedState);
    this.authService.isAuthenticated().subscribe((isAuthenticated) => {
      this.userIsAuthenticated.set(isAuthenticated);
    });
  }

  onClickExpandButton(): void {
    this.expandedState = !this.expandedState;
    this._updateExpandedButtonState(this.expandedState);
    this.localStorageService.set(StatefulUiKey.SidebarExpandedState, this.expandedState);
  }

  signIn(): void {
    this.authService.redirectToSsoLoginPortal();
  }

  signOut(): void {
    this.authService.logOut();
  }

  private _updateExpandedButtonState(state: boolean): void {
    if (state) {
      this.expandButtonIcon = Icons.ArrowLeft;
    } else {
      this.expandButtonIcon = Icons.ArrowRight;
    }
  }
}
