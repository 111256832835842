import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { AuthService } from '@garmin-avcloud/avcloud-web-utils';
import { of, switchMap } from 'rxjs';

export const sessionGuard: CanActivateFn = (next: ActivatedRouteSnapshot) => {
  const authService = inject(AuthService);
  return authService.isAuthenticated()
    .pipe(
      switchMap((isAuthenticated) => {
        if (isAuthenticated) {
          return of(true);
        } else {
          const attemptedPath = next.url[0].path;
          const parameter = ".com%2F";
          let loginPortal = authService.getSsoPortalUrl();
          loginPortal = loginPortal.substring(0, loginPortal.lastIndexOf(parameter) + parameter.length);
          const redirectPath = loginPortal + attemptedPath;
          authService.logOut(redirectPath);
          return of(false);
        }
      })
    );
};


