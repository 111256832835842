import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { HTTP_INTERCEPTOR_CONFIG_TOKEN, LocalStorageKey, LocalStorageService, OAuthTokenResponse } from '@garmin-avcloud/avcloud-web-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerIdInjectorService implements HttpInterceptor {
  private readonly CUSTOMER_ID_HEADER = 'X-Garmin-Customer-Id';
  private readonly httpInterceptorConfig = inject(HTTP_INTERCEPTOR_CONFIG_TOKEN);
  private readonly localStorageService = inject(LocalStorageService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Bypass this interceptor if the URL is in an ignore list.
    if (this.httpInterceptorConfig.ignoreList != null && this.httpInterceptorConfig.ignoreList.some((url) => request.url.startsWith(url))) {
      return next.handle(request);
    }

    let headers = request.headers;

    const customerGuid = this.localStorageService.get<OAuthTokenResponse>(LocalStorageKey.OAuthTokenResponse)?.customerId;
    if (customerGuid != null && !headers.has(this.CUSTOMER_ID_HEADER)) {
      headers = headers.set(this.CUSTOMER_ID_HEADER, customerGuid);
    }

    const clonedRequest = request.clone({ headers });
    return next.handle(clonedRequest);
  }
}
