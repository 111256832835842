import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'pilot-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Output() readonly sidebarVisibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  showSidebar: boolean = false;

  toggleSidebar(): void {
    this.showSidebar = !this.showSidebar;
    this.sidebarVisibilityChange.emit(this.showSidebar);
  }
}
